
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import PreView from '@/components/preview/PreView.ts'
import AxiosAdapter from '../request/AxiosAdapter'

export default defineComponent({
  name: 'qrCode',

  setup() {
    const documentList: any = reactive({
      values: []
    })
    const router = useRouter()
    const fileUrl = ref()
    const previewFun = (item: any) => {
      PreView(item, router)
      fileUrl.value = item
    }
    const downloadFun = (item: any) => {
      window.open(
        process.env.VUE_APP_BASE_API +
          '/file/fileDownLoad?fileUrl=' +
          encodeURI(item)
      )
    }
    onMounted(async () => {
      // 获取信息
      const route = useRoute()
      // 获取后台信息
      const res = await AxiosAdapter.get<any, any>({
        url: '/webElectronic/getDocumentInfo',
        data: route.query
      })
      documentList.values = res
    })
    return {
      documentList,
      downloadFun,
      previewFun
    }
  }
})
